var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prefixUrl = prefixUrl;

function prefixUrl(prefix) {
  return function (requestParams) {
    const {
      url = ""
    } = requestParams;
    return { ...requestParams,
      url: `${prefix}${url}`
    };
  };
}

export default exports;
export const __esModule = exports.__esModule;
const _prefixUrl = exports.prefixUrl;
export { _prefixUrl as prefixUrl };